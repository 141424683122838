<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card
          color="success"
          icon="mdi-chat-outline"
          inline
          title="Détail des présences"
          class="px-5 py-3 mb-5"
        >
          <v-card
            v-for="presence in infoPresences"
            :key="presence.salon"
          >
            <v-card-title>
              {{ presence.salon }}
            </v-card-title>
            <v-card-subtitle class="my-1 text-subtitle-1">
              Identifiant usager: {{ presence.usager }}
            </v-card-subtitle>
            <v-card-text>
              <v-data-table
                :items="presence.listeSessionsPresences"
                :headers="headers"
              />
            </v-card-text>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

  import restApiService from '@/services/restApiService.js'

  export default {
    data: () => ({
      salons: {},
      headers: [
        { text: 'Date entrée', value: 'dateEntree' },
        { text: 'Date notification', value: 'dateNotification' },
      ],
      getInfoPresenceInterval: null,
      infoPresences: [],
    }),

    mounted () {
      this.init()
    },
    created () {

    },
    beforeDestroy() {
      clearInterval(this.getInfoPresenceInterval)
    },
    methods: {
      init() {
        this.getInfoPresences()
        this.getInfoPresenceInterval = setInterval(() => {
          this.getInfoPresences()
        }, 1000)
      },
      getInfoPresences() {
        restApiService
          .get('/api/presences/info/')
          .then(result => {
            console.log('presences info api', result)
            this.infoPresences = result.data
            // this.infoPresences = result.data.sort((a, b) => (a.salon > b.salon) ? 1 : ((b.salon > a.salon) ? -1 : 0))
          })
          .catch(error => alert(error))
      },
    },
  }
</script>
